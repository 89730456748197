.battleMapView-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.battleMapView-canvas {
  width: 100%;
  height: 100%;
}
