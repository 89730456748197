body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  overflow-x: hidden;
}

ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  max-width: 400px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.hide {
  display: none;
  visibility: hidden;
}

.main {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 4vw;
  padding-top: 1vw;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: white;
  overflow-x: hidden;
}
