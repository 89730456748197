.rotg-div-main {
    text-align: center;
    justify-content: center;
}

.rotg-p-title {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.rotg-input-spellName {
    margin: 1em;
    width: 12em;
}

.rotg-p-newSpell {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.rotg-div-stats {
    margin: 2rem 0 0.5rem 0;
}

.rotg-div-stats > p {
    margin: 0;
    padding: 0.1rem;
    font-size: 1.5rem;
}

.rotg-div-options {
    margin: 0.5rem;
    display: inline-block;
}

.rotg-div-option {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.rotg-div-option > p {
    margin: 0;
    font-size: 1.3rem;
    padding-left: 0.3rem;
}