.navlink {
    color: white;
    display: inline-block;
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    padding: 10px 20px;
    border-radius: 1rem;
    white-space: nowrap;
}

li.active, .navlink:hover {
    background-color: #494949;
    border-radius: 1rem;
}

.navlink:hover {
    transition: .6s;
}
